import TokenLogo from '../components/TokenLogo';
import { protocolManifest } from '../components/manifest/manifest';
import CustomTooltip from '../components/tooltip/CustomTooltip';
import { logEvent } from '../lib/amplitude';
import Bank from '../svg/bank';
import { Calendar } from '../svg/calendar';
import { Cash } from '../svg/cash';
import CashCoin from '../svg/cash-coin';
import Clock from '../svg/clock';
import DatabaseUp from '../svg/database-up';
import { Dollar } from '../svg/dollar';
import { FileDiff } from '../svg/file-diff';
import Flag from '../svg/flag';
import { GraphUp } from '../svg/graph-up';
import { InfoCircle } from '../svg/info-circle';
import { Percent } from '../svg/percent';
import { PinFill } from '../svg/pin-fill';
import { PlusSlashMinus } from '../svg/plus-slash-minus';
import { Wallet } from '../svg/wallet-2';
import { AUTH_TOKEN_KEY, DEMO_WALLET, ETHEREUM_MAINNET_ID, protocolManifestMap } from './constants';
import { getLocalStorage } from './localstorage';
import axios from 'axios';
import { getRpcUrlsForChain } from 'chain-config';
import { ethers } from 'ethers';
import { print } from 'graphql';
import moment from 'moment';
import { useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const config = {
  headers: {
    'api-key': process.env.NEXT_ARCHIVE_KEY,
  },
};

export const populateConifg = {
  headers: {
    'populate-day-data-key': process.env.NEXT_ARCHIVE_KEY,
  },
};

export const checkRegularWallet = (walletAddress) => {
  if (!walletAddress.startsWith('0x')) return false;
  return walletAddress.length === 42 && !hasWhiteSpace(walletAddress) && !containsSpecialChars(walletAddress);
};

const checkDotEthWallet = (walletAddress) => {
  if (!walletAddress.endsWith('.eth')) return false;
  return !hasWhiteSpace(walletAddress) && !containsSpecialCharsExceptDot(walletAddress);
};

const checkEvmosWallet = (walletAddress) => {
  const walletAddressRegex = /^(evmos1[a-z0-9]{38})$/i;
  const isValid =
    walletAddressRegex.test(walletAddress) && !hasWhiteSpace(walletAddress) && !containsSpecialChars(walletAddress);
  return isValid;
};

const checkSolanaWallet = (walletAddress) => {
  const walletAddressRegex = /^[1-9A-HJ-NP-Za-km-z]+$/;
  const isValid = walletAddressRegex.test(walletAddress);
  return walletAddress.length >= 32 && walletAddress.length <= 44 && isValid;
};

const checkCosmosWallet = (walletAddress) => {
  const walletAddressRegex = /^(osmo1[a-z0-9]{38}|cosmos1[a-z0-9]{38})$/i;
  const isValid =
    walletAddressRegex.test(walletAddress) && !hasWhiteSpace(walletAddress) && !containsSpecialChars(walletAddress);
  return isValid;
};

const checkAvalancheWallet = (walletAddress) => {
  const walletAddressRegex = /^avax1[a-hj-np-z0-9]+$/;
  const isValid =
    walletAddressRegex.test(walletAddress) && !hasWhiteSpace(walletAddress) && !containsSpecialChars(walletAddress);
  return isValid;
};

export const walletAddressCheck = (walletAddress) => {
  const protocolChecks = {
    evmos: checkEvmosWallet,
    solana: checkSolanaWallet,
    avalanche: checkAvalancheWallet,
    cosmos: checkCosmosWallet,
    regular: checkRegularDotEthFallback,
  };

  // const quickCheckRegex = /^[a-zA-Z0-9]{26,}$/;
  // if (!quickCheckRegex.test(walletAddress)) {
  //   return false;
  // }

  const results = Object.entries(protocolChecks).reduce((acc, [key, checkFunction]) => {
    try {
      acc[key] = checkFunction(walletAddress);
    } catch (error) {
      acc[key] = false;
      console.error(`Error in ${key} check:`, error);
    }
    return acc;
  }, {});

  const isValid = Object.values(results).some((result) => result === true);

  return isValid;
};

const checkRegularDotEthFallback = (walletAddress) => {
  return checkRegularWallet(walletAddress) || checkDotEthWallet(walletAddress);
};

function hasWhiteSpace(s) {
  return /\s/g.test(s);
}

function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

function containsSpecialCharsExceptDot(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};'"\\|,<>\/?~]/;
  return specialChars.test(str);
}

export async function decodeENS(ensDomain) {
  try {
    const RPC_URL = getRpcUrlsForChain(ETHEREUM_MAINNET_ID)[0];
    const provider = new ethers.providers.StaticJsonRpcProvider(RPC_URL);
    const address = await provider.resolveName(ensDomain);
    addUserAdddressToUrl(address);
    return address;
  } catch (error) {
    console.error('Error decoding ENS domain:', error);
    return null;
  }
}

export const addUserAdddressToUrl = (address) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.pathname += `/${address}`;
  window.history.pushState({}, '', currentUrl);
};

export const TokenType = {
  RECEIPT: 'receipt',
  NON_RECEIPT: 'non_receipt',
  INCOME: 'income',
};

export function displayPrettyNumber(number, shortened = false) {
  if (!number) return '0.00';
  number = parseFloat(number);
  if (isNaN(number)) {
    console.log(`unable to parse ${number} -- please check!`);
    return '0.00';
  }

  const formatNumberWithCommas = (number) => {
    let [integerPart, decimalPart] = number.toString().split('.');

    let result = '';
    let count = 0;

    // Iterate over the integer part from the end to the beginning
    for (let i = integerPart.length - 1; i >= 0; i--) {
      // Append the current digit to the result string
      result = integerPart[i] + result;
      count++;

      // After every three digits, add a comma (but not at the beginning)
      if (count % 3 === 0 && i !== 0) {
        result = ',' + result;
      }
    }

    // If there is a decimal part, append it to the result with a dot
    if (decimalPart) {
      result += '.' + decimalPart;
    }

    return result;
  };

  const fractionDigits = toDecimalPlaceDigits(number);
  try {
    const absNumber = Math.abs(number);

    const thresholds = [
      { value: 1000000000, symbol: 'B', fractionDigits: shortened ? 0 : 2 },
      { value: 1000000, symbol: 'M', fractionDigits: shortened ? 0 : 2 },
    ];

    const threshold = thresholds.find((th) => absNumber >= th.value);
    if (threshold) {
      return `${(number / threshold.value).toFixed(threshold.fractionDigits)}${threshold.symbol}`;
    }
    if (absNumber >= 1000 && absNumber < 1000000) {
      return formatNumberWithCommas(number.toFixed(fractionDigits));
    }

    if (absNumber > 0 && absNumber < 0.000001) {
      return '<0.000001';
    }

    return number.toFixed(fractionDigits);
  } catch (e) {
    console.log(`unable to parse ${number} -- please check!`);
    return '0';
  }
}

export function toDecimalPlaceDigits(input) {
  const num = Math.abs(input);
  if (num === 0) {
    return 2;
  }

  if (num >= 1) {
    return 2;
  }

  if (num >= 0.99) {
    return 3;
  }

  if (num >= 0.1) {
    return 4;
  }
  if (num >= 0.01) {
    return 5;
  }
  if (num >= 0.001) {
    return 6;
  }
  if (num >= 0.0001) {
    return 7;
  }

  if (num >= 0.00001) {
    return 8;
  }

  if (num >= 0.000001) {
    return 9;
  }

  return 3;
}

export function formatUSD(amount, noDollarSign = false) {
  if (!amount) return '0.00';

  amount = parseFloat(amount);
  let options;
  if (Math.abs(amount) < 10000) {
    options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  } else {
    options = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
  }

  let formattedAmount = amount.toLocaleString('en-US', options);
  if (!noDollarSign) {
    formattedAmount = '$' + formattedAmount;
  }

  return formattedAmount;
}

export const getColumnIconExited = (columnId) => {
  switch (columnId) {
    case 'costBasis':
      return <PinFill />;
    case 'exitValue':
      return <Dollar />;
    case 'marketGainPct':
      return <GraphUp />;
    case 'income':
      return <Cash />;
    case 'incomeApy':
      return <Percent />;
    case 'impermanentLossPctExited':
      return <FileDiff />;
    case 'totalEstExitValue':
      return <Wallet />;
    case 'exitDate':
      return <Calendar />;
    default:
      return null;
  }
};

export const getColumnIcon = (columnId) => {
  switch (columnId) {
    case 'investment':
      return <DatabaseUp />;
    case 'currentValue':
      return <CashCoin />;
    case 'currentValueLB':
      return <Bank />;
    case 'marketGainPct':
      return <GraphUp />;
    case 'totalIncome':
      return <Cash />;
    case 'incomeApy':
      return <Percent />;
    case 'impermanentLossPct':
      return <FileDiff />;
    case 'totalEstPositionValue':
      return <Wallet />;
    case 'totalCostBasis':
      return <PinFill />;
    case 'totalSupplyValue':
    case 'totalDebtValue':
      return <Bank />;
    case 'totalValueChange':
      return <GraphUp />;
    case 'costBasisLB':
      return <PinFill />;
    case 'type':
      return <Flag />;
    case 'currentValueLB':
      return <Bank />;
    case 'assetValueChangeLB':
      return <GraphUp />;
    case 'apyLB':
      return <FileDiff />;
    case 'totalLendingYield':
    case 'totalDebtCost':
    case 'lendingYield':
    case 'debtCost':
      return <CashCoin />;
    case 'totalNetYield':
      return <PlusSlashMinus />;
    case 'positionAge':
      return <Clock />;
    default:
      return null;
  }
};

export const getColumnValueExited = (columnId, entry) => {
  switch (columnId) {
    case 'costBasis':
      return `${formatUSD(entry?.exitedCostUsd ?? 0)}`;
    case 'exitValue':
      return `${formatUSD(entry?.exitedValueUsd ?? 0)}`;
    case 'marketGainPct':
      return `${entry?.exitedNetGainPct > 0 ? '+' : ''}${displayPrettyNumber(entry?.exitedNetGainPct ?? 0)}%`;
    case 'incomeApy':
      return `${displayPrettyNumber(entry?.exitedIncomeAPY ?? 0)}%`;
    case 'impermanentLossPctExited':
      return `${displayPrettyNumber(entry?.exitedRoiVsHodlPct ?? 0)}%`;
    case 'totalEstExitValue':
      return `${formatUSD(Number(entry?.exitedValueUsd ?? 0) + Number(entry?.exitedCollectedIncomeUsd ?? 0))}`;
    case 'exitDate':
      return entry?.date;
    case 'type':
      return `${entry?.isLiabilityPosition ? 'Borrow' : 'Supply'}`;
    case 'costBasisLB':
      return `${formatUSD(
        entry?.isLiabilityPosition ? Math.abs(entry?.exitedCostUsd ?? 0) : entry?.exitedCostUsd ?? 0,
      )}`;
    case 'exitValueLB':
      return `${formatUSD(entry?.isLiabilityPosition ? Math.abs(entry?.exitedValueUsd ?? 0) : entry?.exitedValueUsd)}`;
    case 'assetValueChangeLB':
      return `${displayPrettyNumber(
        entry?.isLiabilityPosition ? Math.abs(entry?.exitedNetGainPct ?? 0) : entry?.exitedNetGainPct,
      )}%`;
    case 'apyLB':
      return `${displayPrettyNumber(
        entry?.isLiabilityPosition ? Math.abs(entry?.exitedPositionShareAPY ?? 0) : entry?.exitedPositionShareAPY,
      )}%`;
    case 'tokenName':
      return `${entry?.tokenName.toUpperCase()}`;
    case 'tokenChange':
      return (
        <>
          {displayPrettyNumber(entry?.exitedIfHeldAmountToken)} {renderArrow()}
          {displayPrettyNumber(entry?.exitedTokenAmount)}
        </>
      );
    case 'priceAtExit':
      return `$${displayPrettyNumber(Number(entry?.priceUsd))}`;
    case 'tokenChangeValue':
      return `${formatUSD(
        (Number(entry?.exitedTokenAmount) - Number(entry?.exitedIfHeldAmountToken)) * Number(entry?.priceUsd),
      )}`;
    case 'thisPositionValue':
      return `${formatUSD(entry?.exitedValueUsd ?? 0)}`;
    case 'ifHeld':
      return `${formatUSD(entry?.exitedHodlValueUsd ?? 0)}`;
    case 'roiVsHodl':
      return `${formatUSD(entry?.exitedRoiVsHodlUsd ?? 0)}`;
    case 'if100%ETH':
      return `${formatUSD(entry?.exitedIfHeldAllAmountEthValueUsd ?? 0)}`;
    case 'if100%BTC':
      return `${formatUSD(entry?.exitedIfHeldAllAmountBtcValueUsd ?? 0)}`;
    case 'collected':
      return `${displayPrettyNumber(entry?.exitedCumulativeCollectedIncomeAmount ?? 0)}`;
    case 'collectedIncome':
      return `${formatUSD(entry?.exitedCollectedIncomeUsd ?? 0)}`;
    case 'yieldUSD':
      return `${formatUSD(entry?.exitedPositionShareChangeUsd)}`;
    case 'apyUSD':
      return `${displayPrettyNumber(entry?.exitedPositionShareUsdAPY)}%`;
    case 'yieldInTokens':
      return `${displayPrettyNumber(entry?.exitedPositionShareChange)}`;
    case 'tokenAPY':
      return `${displayPrettyNumber(entry?.exitedPositionShareAPY)}%`;
    case 'lendingYield':
      return `${formatUSD(entry?.exitedPositionShareChangeUsd ?? 0)}`;
    case 'debtCost':
      return `${formatUSD(entry?.exitedPositionShareChangeUsd ?? 0)}`;
    default:
      return null;
  }
};

export const getColumnValue = (columnId, entry) => {
  switch (columnId) {
    case 'investment':
      return `${formatUSD(entry?.userPrincipalCostUsd ?? 0)}`;
    case 'currentValue':
      return `${formatUSD(entry?.positionUsdValueAtBlock ?? 0)}`;
    case 'marketGainPct':
      return `${entry?.netMarketGainPct > 0 ? '+' : ''}${displayPrettyNumber(entry?.netMarketGainPct ?? 0)}%`;
    case 'totalIncome':
      return `$${displayPrettyNumber(entry?.cumulativeCollectedIncomeUsd + entry?.pendingIncomeUsd ?? 0)}`;
    case 'incomeApy':
      return `${displayPrettyNumber(entry?.incomeApyInception ?? 0)}%`;
    case 'impermanentLossPct':
      return `${displayPrettyNumber(entry?.roiVsHodlPct ?? 0)}%`;
    case 'totalEstPositionValue':
      return `${formatUSD(
        Number(entry?.positionUsdValueAtBlock ?? 0) +
          Number(entry?.cumulativeCollectedIncomeUsd ?? 0) +
          Number(entry?.pendingIncomeUsd ?? 0),
      )}`;
    case 'type':
      return `${entry?.isLiabilityPosition ? 'Borrow' : 'Supply'}`;
    case 'costBasisLB':
      return `${formatUSD(entry?.userPrincipalCostUsd ?? 0)}`;
    case 'currentValueLB':
      return `${formatUSD(entry?.positionUsdValueAtBlock ?? 0)}`;
    case 'assetValueChangeLB':
      return `${displayPrettyNumber(entry?.netMarketGainPct ?? 0)}%`;
    case 'apyLB':
      return `${displayPrettyNumber(entry?.positionShareAPY ?? 0)}%`;
    case 'lendingYield':
      return `${formatUSD(entry?.positionShareChangeUsd ?? 0)}`;
    case 'debtCost':
      return `${formatUSD(entry?.positionShareChangeUsd ?? 0)}`;
    case 'tokenName':
      return `${entry?.tokenName.toUpperCase()}`;
    case 'tokenChange':
      return (
        <>
          {displayPrettyNumber(Number(entry?.ifHeldAmountToken))} {renderArrow()}
          {displayPrettyNumber(Number(entry?.tokenAmountAtBlock))}
        </>
      );
    case 'currentPrice':
      return `$${displayPrettyNumber(Number(entry?.priceUsd))}`;
    case 'tokenChangeValue':
      return `${formatUSD(
        (Number(entry?.tokenAmountAtBlock) - Number(entry?.ifHeldAmountToken)) * Number(entry?.priceUsd),
      )}`;
    case 'collectedIncome':
      return `${formatUSD(entry?.cumulativeCollectedIncomeUsd ?? 0)}`;
    case 'pendingIncome':
      return `${formatUSD(entry?.pendingIncomeUsd ?? 0)}`;
    case 'pending':
      return `${displayPrettyNumber(entry?.pendingIncomeAmount ?? 0)}`;
    case 'collected':
      return `${displayPrettyNumber(entry?.collectedIncomeAmount ?? 0)}`;
    case 'total':
      return `${displayPrettyNumber(entry?.totalIncomeAmount ?? 0)}`;
    case 'thisPositionValue':
      return `${formatUSD(entry?.positionUsdValueAtBlock ?? 0)}`;
    case 'ifHeld':
      return `$${displayPrettyNumber(entry?.hodlValueUsd ?? 0)}`;
    case 'roiVsHodl':
      return `$${displayPrettyNumber(entry?.roiVsHodlUsd ?? 0)}`;
    case 'if100%ETH':
      return `$${displayPrettyNumber(entry?.ifHeldAllAmountEthValueUsd ?? 0)}`;
    case 'if100%BTC':
      return `$${displayPrettyNumber(entry?.ifHeldAllAmountBtcValueUsd ?? 0)}`;
    case 'yieldUSD':
      return `$${displayPrettyNumber(entry?.positionShareChangeUsd)}`;
    case 'apyUSD':
      return `${displayPrettyNumber(entry?.positionShareUsdAPY)}%`;
    case 'yieldInTokens':
      return `${displayPrettyNumber(entry?.positionShareChange)}`;
    case 'tokenAPY':
      return `${displayPrettyNumber(entry?.positionShareAPY)}%`;
    case 'positionAge':
      return `${formatEntry('positionAge', entry)}`;
    default:
      return null;
  }
};

export const checkTokenAndType = (inputString) => {
  const capitalLettersRegex = /[A-Z]{2,}/g; // Match sequences of at least two capital letters

  const capitalLettersMatch = inputString.match(capitalLettersRegex);
  const hasStableKeyword = inputString.includes('STABLE');

  const hasVariableKeyword = inputString.includes('VARIABLE');

  let token = '';
  if (capitalLettersMatch) {
    token = capitalLettersMatch.join('');
  }

  let keyword = '';
  if (hasStableKeyword) {
    keyword = ' (stable)';
  } else if (hasVariableKeyword) {
    keyword = ' (variable)';
  }

  return token + ' ' + keyword;
};

export const cryptoGetRandomKey = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint8Array(2);
  crypto.getRandomValues(array);
  return (array[0].toString(36) + array[1].toString(36)).substring(0, 8);
};

export const assemblePositionName = (entry, isLBPosition) => {
  let result = '';
  const nonReceiptTokens = entry.tokens.filter((tokenEntry) =>
    isLBPosition ? tokenEntry.tokenType === TokenType.RECEIPT : tokenEntry.tokenType === TokenType.NON_RECEIPT,
  );

  if (nonReceiptTokens.length > 0) {
    nonReceiptTokens.forEach(({ tokenName }, idx) => {
      const appendResult = idx === 0 ? tokenName.toUpperCase() : ` / ${tokenName.toUpperCase()}`;
      result = result.concat(appendResult);
    });
  } else {
    return formatAddress(entry.positionIdentifier);
  }

  return result;
};

export const extractValidatorIndex = (str) => {
  const match = str.match(/validatorIndex-(\d+)/);
  return match ? match[1] : null;
};

export const getBeaconChainScanLink = (txHash) => {
  const validatorIndex = extractValidatorIndex(txHash);
  return `https://beaconcha.in/validator/${validatorIndex}`;
};

export const getBlockchainScanLink = (protocolKey, txHash) => {
  const blockchainScanLinks = {
    eth: `https://etherscan.io/tx/`,
    bsc: 'https://bscscan.com/tx/',
    solana: 'https://solscan.io/tx/',
    polygon: 'https://polygonscan.com/tx/',
    aurora: 'https://explorer.aurora.dev/tx/',
    evmos: 'https://escan.live/tx/',
    arbitrum: 'https://arbiscan.io/tx/',
    optimism: 'https://optimistic.etherscan.io/tx/',
    base: 'https://basescan.org/tx/',
    ronin: 'https://app.roninchain.com/tx/',
    cosmoshub_staking: 'https://www.mintscan.io/cosmos/tx/',
    avalanche: 'https://snowtrace.io/tx/',
    scroll: 'https://scrollscan.com/tx/',
    zksync: 'https://explorer.zksync.io/tx/',
    gnosis: 'https://gnosisscan.io/tx/',
  };

  const matchingKey = Object.keys(blockchainScanLinks).find((key) => protocolKey.includes(key));

  if (matchingKey) {
    const blockchainScanLink = blockchainScanLinks[matchingKey];
    return `${blockchainScanLink}${txHash}`;
  } else {
    return 'Invalid protocol key!';
  }
};

export const renderTooltip = (name, exited) => {
  switch (true) {
    case name === 'If 100% ETH':
      return (
        <CustomTooltip
          content={
            exited
              ? 'Hypothetical value at the exit time if the user had purchased ETH instead of this asset. Additional deposits increase, and any exits reduce this value. This value includes the transaction fee paid to acquire this asset.'
              : 'Hypothetical value if the user had purchased ETH instead of this asset. Additional deposits buy ETH at ETH price at that time, and any exits reduce this value by the proportion of the position that is exited. This value includes the transaction fee paid to acquire this asset.'
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'If 100% BTC':
      return (
        <CustomTooltip
          content={
            exited
              ? ' Hypothetical value at the exit time if the user had purchased BTC instead of this asset. Additional deposits increase, and any exits reduce this value. This value includes the transaction fee paid to acquire this asset.'
              : 'Hypothetical value if the user had purchased Bitcoin instead of this asset. Additional deposits buy BTC at BTC price at that time, and any exits reduce this value by the proportion of the position that is exited. This value includes the transaction fee paid to acquire this asset.'
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'HODL Value':
      return (
        <CustomTooltip content="Hypothetical USD value if the user didn’t enter the position.">
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'HODL amount':
      return (
        <CustomTooltip
          content={
            exited
              ? 'Hypothetical USD value at the exit time if the user didn’t enter the position'
              : 'Original token amount at the time of entering the position'
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'Current balance':
      return (
        <CustomTooltip content="Current Token amount available to withdraw.">
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'Exit balance':
      return (
        <CustomTooltip content="Exited token amount">
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'Exit token price':
      return (
        <CustomTooltip content="Token price at the time of exit">
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'Investment':
      return (
        <CustomTooltip
          content={
            <div style={{ textAlign: 'left' }}>
              <strong>USD</strong>
              <div></div>
              <div>
                Average USD cost of position: This reflects the average price in USD for this position. Deposits
                increase this average, while withdrawals decrease it based on the percentage withdrawn.
              </div>
              <br />
              <div>
                <strong>Tokens</strong>
                <div></div>
                <div>
                  The cost basis of the position in terms of tokens. Actual deposits, withdraws, and transfers will
                  affect this number, but changes like auto-compounding, rebasing, and interest earned do not.
                </div>
              </div>
            </div>
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'Yield':
      return (
        <CustomTooltip
          content={
            <div style={{ textAlign: 'left' }}>
              <strong>USD</strong>
              <div></div>
              <div>
                The USD value of the additional tokens in the position from protocol mechanisms like rebasing or
                auto-compounding. This is the token yield multiplied by the tokens current price.
              </div>
              <br />
              <div>
                <strong>Tokens</strong>
                <div></div>
                Unrealized position token gain/loss: This measures the difference between your initial investment in
                position tokens and their current quantity. In rebasing scenarios, it represents the total net rebasing
                accrued.
              </div>
            </div>
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'APY':
      return (
        <CustomTooltip
          content={
            <div style={{ textAlign: 'left' }}>
              <strong>USD</strong>
              <div></div>
              <div>
                An APY (Annual Percentage Yield) figure calculated using the USD Invested and Yield figures in this
                column and extrapolating that yield to a full year.
              </div>
              <br />
              <div>
                <strong>Tokens</strong>
                <div></div>
                An APY (Annual Percentage Yield) figure calculated using the tokens Invested and Yield figures in this
                column and extrapolating that yield to a full year.
              </div>
            </div>
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    case name === 'Debt':
      return (
        <CustomTooltip
          content={
            <div style={{ textAlign: 'left' }}>
              <strong>USD</strong>
              <div></div>
              <div>
                The USD value of the additional tokens in the position from protocol mechanisms like rebasing or
                auto-compounding. This is the token debt multiplied by the tokens current price.
              </div>
              <br />
              <div>
                <strong>Tokens</strong>
                <div></div>
                Unrealized position token gain/loss: This measures the difference between your initial investment in
                position tokens and their current quantity. In rebasing scenarios, it represents the total net rebasing
                accrued.
              </div>
            </div>
          }
        >
          <InfoCircle />
        </CustomTooltip>
      );
    default:
      return null;
  }
};

export const ONE_DAY_IN_SECONDS = 3600 * 24;

export const mapOperationType = (operation, protocolKey, positionSnapshot) => {
  switch (operation.operationType) {
    case 'deposit':
      return isProtocolLB(protocolKey) && !isLiabilityPosition(positionSnapshot)
        ? 'supply'
        : isProtocolLB(protocolKey)
        ? 'repay'
        : operation.operationType;
    case 'withdraw':
      return isProtocolLB(protocolKey) && !isLiabilityPosition(positionSnapshot)
        ? 'withdraw'
        : isProtocolLB(protocolKey)
        ? 'borrow'
        : operation.operationType;
    case 'null_op':
      return 'staking operation';
    case 'transfer_in':
      return isProtocolLB(protocolKey) && !isLiabilityPosition(positionSnapshot) ? 'transfer supply in' : 'transfer in';
    case 'transfer_out':
      return isProtocolLB(protocolKey) && !isLiabilityPosition(positionSnapshot)
        ? 'transfer supply out'
        : 'transfer out';
    default:
      return operation.operationType;
  }
};

export const maybeRenderTokenAmountsAndPrices = (operation) => {
  if (['withdraw', 'deposit'].includes(operation.operationType)) {
    const filtered = operation.tokens
      .filter((token) => token.tokenDirection === (operation.operationType === 'withdraw' ? 'Output' : 'Input'))
      .filter((token) => token.tokenType === TokenType.NON_RECEIPT);
    return filtered.map((token, idx) => {
      return (
        <div key={cryptoGetRandomKey()}>
          Token: {token.tokenName.toUpperCase()}
          <br />
          Amount:{' '}
          {displayPrettyNumber(
            operation.operationType === 'withdraw' ? token.amountWithdrawnChange : token.amountDepositedChange,
          )}
          <br />
          Price: ${displayPrettyNumber(token.tokenPriceUsd)}
          {idx === filtered.length - 1 ? null : (
            <>
              <br /> <br />
            </>
          )}
        </div>
      );
    });
  }
  return null;
};

export const columnDataExitedNonLB = [
  {
    id: 'costBasis',
    numeric: true,
    disablePadding: false,
    label: 'Investment',
  },
  {
    id: 'exitValue',
    numeric: true,
    disablePadding: false,
    label: 'Exit Value',
  },
  {
    id: 'marketGainPct',
    numeric: true,
    disablePadding: false,
    label: 'Market Gain',
  },
  {
    id: 'totalEstExitValue',
    numeric: true,
    disablePadding: false,
    label: 'Total Exit Value',
  },
  {
    id: 'exitDate',
    numeric: true,
    disablePadding: false,
    label: 'Exit Date',
  },
];

export const columnDataCurrentNonLB = [
  {
    id: 'investment',
    numeric: true,
    disablePadding: false,
    label: 'Investment',
  },
  {
    id: 'currentValue',
    numeric: true,
    disablePadding: false,
    label: 'Current Value',
  },
  {
    id: 'marketGainPct',
    numeric: true,
    disablePadding: false,
    label: 'Market Gain',
  },
  {
    id: 'totalEstPositionValue',
    numeric: true,
    disablePadding: false,
    label: 'Total Position Value',
  },
  {
    id: 'positionAge',
    numeric: true,
    disablePadding: false,
    label: 'Position Age',
  },
];

export const columnDataCurrentLB = [
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'costBasisLB',
    numeric: true,
    disablePadding: false,
    label: 'Investment',
  },
  {
    id: 'currentValueLB',
    numeric: true,
    disablePadding: false,
    label: 'Current Value',
  },
  {
    id: 'assetValueChangeLB',
    numeric: true,
    disablePadding: false,
    label: 'Asset Value Change',
  },
  {
    id: 'apyLB',
    numeric: true,
    disablePadding: false,
    label: 'APY',
  },
  {
    id: 'lendingYield',
    numeric: true,
    disablePadding: false,
    label: 'Lending Yield',
  },
  {
    id: 'debtCost',
    numeric: true,
    disablePadding: false,
    label: 'Interest Expense',
  },
  {
    id: 'positionAge',
    numeric: true,
    disablePadding: false,
    label: 'Position Age',
  },
];

export const columnDataExitedLB = [
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'costBasisLB',
    numeric: true,
    disablePadding: false,
    label: 'Investment',
  },
  {
    id: 'exitValueLB',
    numeric: true,
    disablePadding: false,
    label: 'Exit Value',
  },
  {
    id: 'assetValueChangeLB',
    numeric: true,
    disablePadding: false,
    label: 'Asset Value Change',
  },
  {
    id: 'apyLB',
    numeric: true,
    disablePadding: false,
    label: 'APY',
  },
  {
    id: 'lendingYield',
    numeric: true,
    disablePadding: false,
    label: 'Lending Yield',
  },
  {
    id: 'debtCost',
    numeric: true,
    disablePadding: false,
    label: 'Interest Expense',
  },
];

export const columnTokens = [
  {
    id: 'tokenName',
    numeric: true,
    disablePadding: false,
    label: '',
  },
  {
    id: 'tokenChange',
    numeric: true,
    disablePadding: false,
    label: 'Token Change',
  },
  {
    id: 'currentPrice',
    numeric: true,
    disablePadding: false,
    label: 'Current Price',
  },
  {
    id: 'tokenChangeValue',
    numeric: true,
    disablePadding: false,
    label: 'Token Change Value',
  },
];

export const columnTokensExited = [
  {
    id: 'tokenName',
    numeric: true,
    disablePadding: false,
    label: '',
  },
  {
    id: 'tokenChange',
    numeric: true,
    disablePadding: false,
    label: 'Token Change',
  },
  {
    id: 'priceAtExit',
    numeric: true,
    disablePadding: false,
    label: 'Price at Exit',
  },
  {
    id: 'tokenChangeValue',
    numeric: true,
    disablePadding: false,
    label: 'Token Change Value',
  },
];

export const isProtocolLB = (protocolKey) => {
  return ['aave', 'compound', 'maker', 'morpho', 'spark', 'fluid', 'moonwell'].some((key) => protocolKey.includes(key));
};

export const isLBProtocolWithMultipleUnderlyingTokens = (protocolKey, tokens) => {
  const trackedTokens = tokens.filter((token) => token.tag === 'tracked_underlying');
  return isProtocolLB(protocolKey) && trackedTokens.length > 1;
};

export const isLiabilityPosition = (positionSnapshot) => positionSnapshot.isLiabilityPosition;

export const getChain = (string) => {
  if (string.toLowerCase().includes('etherfi')) {
    return 'EtherFi';
  } else if (string.toLowerCase().includes('wsteth')) {
    return 'Lido';
  } else if (string.toLowerCase().includes('eth')) {
    return 'Ethereum';
  } else if (string.toLowerCase().includes('avalanche')) {
    return 'Avalanche';
  } else if (string.toLowerCase().includes('polygon')) {
    return 'Polygon';
  } else if (string.toLowerCase().includes('optimism')) {
    return 'Optimism';
  } else if (string.toLowerCase().includes('arbitrum')) {
    return 'Arbitrum';
  } else if (string.toLowerCase().includes('base')) {
    return 'Base';
  } else if (string.toLowerCase().includes('evmos')) {
    return 'Evmos';
  } else if (string.toLowerCase().includes('bsc')) {
    return 'Binance Smart Chain';
  } else if (string.toLowerCase().includes('solana')) {
    return 'Solana';
  } else if (string.toLowerCase().includes('ronin')) {
    return 'Ronin';
  } else if (string.toLowerCase().includes('cosmos')) {
    return 'Cosmos';
  } else if (string.toLowerCase().includes('gnosis')) {
    return 'Gnosis';
  } else if (string.toLowerCase().includes('scroll')) {
    return 'Scroll';
  } else if (string.toLowerCase().includes('zksync')) {
    return 'Zksync';
  }
};

export const filterAndRemoveDuplicates = (data) => {
  const entriesByPositionId = new Map();

  for (const entry of data) {
    const positionId = entry.positionIdentifier;
    const entryTimestamp = entry.timestamp;

    // Check if current entry's timestamp is more recent than the one stored in the map
    if (!entriesByPositionId.has(positionId) || entryTimestamp > entriesByPositionId.get(positionId).timestamp) {
      entriesByPositionId.set(positionId, { ...entry });
    }
  }

  // Convert the map values to an array to return
  return Array.from(entriesByPositionId.values());
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

export const IsMobileDevice = () => {
  const windowSize = useWindowSize();
  const width = windowSize[0];
  return width < 768 && width !== 0;
};

export const getBorrowedOrSuppliedPositionInfo = (data, shouldReturnLiabilityPosition) => {
  return (
    data &&
    data
      .map((entry) => {
        return entry.dayData
          .filter((dayEntry) => dayEntry.isLiabilityPosition === shouldReturnLiabilityPosition)
          .map((filteredEntry) => {
            if (shouldReturnLiabilityPosition) {
              return shouldReturnNegatedValues(filteredEntry);
            }
            return filteredEntry;
          });
      })
      .flat()
  );
};

export const extractKeysWithCapitalLetters = (dataArray) => {
  const capitalLetterRegex = /[A-Z]/;

  const extractedKeys = dataArray.reduce((keys, item) => {
    Object.keys(item).forEach((key) => {
      if (capitalLetterRegex.test(key)) {
        keys.add(key);
      }
    });
    return keys;
  }, new Set());

  return Array.from(extractedKeys);
};

export const getParticularIncomeData = (data, calculateIncomeFn) => {
  const aggregatedIncomeData = {};

  data.forEach((dayDataEntry) => {
    const tokens = dayDataEntry.tokens;

    tokens.forEach((token) => {
      constructIncomeObject(token, calculateIncomeFn, aggregatedIncomeData, dayDataEntry);
    });
  });

  // Get all unique token names from tokens
  const allTokenNames = data.flatMap((dayDataEntry) =>
    dayDataEntry.tokens
      .filter((token) => token.tokenType === TokenType.INCOME)
      .map((token) => token.tokenName.toUpperCase()),
  );

  // Create an entry for tokens without collectedIncomeAmountInUSD > 0
  allTokenNames.forEach((tokenName) => {
    if (!aggregatedIncomeData[tokenName]) {
      aggregatedIncomeData[tokenName] = {
        val: 0,
        timestamp: 0,
      };
    }
  });

  const incomeData = Object.entries(aggregatedIncomeData).map(([name, data]) => ({
    name,
    val: data.val,
  }));

  return incomeData;
};

export const getUniqueIncomeTokens = (data) => {
  const uniqueTokens = new Set();

  data.forEach((entry) => {
    const tokens = entry.tokens;

    tokens.forEach((token) => {
      if (token.InctokenType === TokenType.INCOME) {
        const tokenObject = {
          id: `token${token.tokenAddress}Value`,
          numeric: true,
          disablePadding: false,
          label: token.tokenName.toUpperCase(),
          tokenAddress: token.tokenAddress,
        };

        uniqueTokens.add(JSON.stringify(tokenObject));
      }
    });
  });

  return Array.from(uniqueTokens).map((tokenString) => JSON.parse(tokenString));
};

function constructIncomeObject(token, calculateIncomeFn, aggregatedIncomeData, dayDataEntry) {
  if (token.tokenType === TokenType.INCOME) {
    const tokenName = token.tokenName.toUpperCase();
    const incomeData = calculateIncomeFn(token);

    if (
      !aggregatedIncomeData[tokenName] ||
      (incomeData > 0 && dayDataEntry.timestamp > aggregatedIncomeData[tokenName].timestamp)
    ) {
      aggregatedIncomeData[tokenName] = {
        val: incomeData,
        timestamp: dayDataEntry.timestamp,
      };
    }
  }
}

export const findLatestWithdrawalOrTransferOut = (dataset) => {
  let latestEntry = null;

  for (const entry of dataset) {
    for (const dayDataEntry of entry.dayData) {
      if (dayDataEntry.withdrawalOrTransferOut && dayDataEntry.withdrawalOrTransferOut.value === true) {
        if (!latestEntry || moment(dayDataEntry.date, 'M/D/YYYY').isAfter(moment(latestEntry.date, 'M/D/YYYY'))) {
          latestEntry = dayDataEntry;
        }
      }
    }
  }

  return latestEntry;
};

export function getNonReceiptTokensByDate(data, isExited) {
  const result = [];
  for (let i = 0; i < data.length; i++) {
    const entry = data[i];
    const lastIndexWithExited = findLastExitedIndex(data);
    const { date, tokens } = entry;
    const relevantTokens = tokens;
    const tokenObject = {};
    for (const token of relevantTokens) {
      const { tokenName, tokenAmountAtBlock, exitedTokenAmount } = token;
      if (isExited && i === lastIndexWithExited && token.tokenType === TokenType.NON_RECEIPT) {
        tokenObject[tokenName.toUpperCase()] = exitedTokenAmount;
      } else if (token.tokenType === TokenType.NON_RECEIPT) {
        tokenObject[tokenName.toUpperCase()] = tokenAmountAtBlock;
      }
    }

    if (Object.keys(tokenObject).length > 0) {
      const entryObject = { date, ...tokenObject };
      result.push(entryObject);
    }
  }

  return result;
}

export const CustomYAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x - 4},${y - 18})`}>
      <text className="font-size-12" x={0} y={0} dy={16} textAnchor="end" fill="var(--color-text)">
        ${displayPrettyNumber(payload.value)}
      </text>
    </g>
  );
};

export function splitSessions(data) {
  const sessions = [];
  let currentSession = [];

  for (const item of data) {
    if (item.isNewSession) {
      if (currentSession.length > 0) {
        sessions.push(currentSession);
      }
      currentSession = [];
    }
    currentSession.push(item);
  }

  if (currentSession.length > 0) {
    sessions.push(currentSession);
  }

  return sessions;
}

export function transformData(inputData, decodedDate) {
  const output = [];
  let lastEntry = null;

  for (const entry of inputData) {
    if (entry.isNewSession) {
      lastEntry = null; // Reset lastEntry for a new session
    }

    if (entry.date === decodedDate) {
      if (entry.userProtocolPositionSnapshotOperations.length > 0) {
        let operationType = entry.userProtocolPositionSnapshotOperations[0].operationType;
        if (lastEntry && lastEntry.operationType === 'withdraw') {
          operationType = 'WITHDRAW';
        }

        entry.operationType = operationType;
        output.push(entry);
      }
    }

    lastEntry = entry;
  }

  return output;
}

export function findEntryByDate(data, decodedDate) {
  for (const session of data) {
    for (const entry of session) {
      let formattedDateTime = moment(entry.timestamp * 1000)
        .utc()
        .format('YYYY-MM-DD, HH:mm:ss');
      if (formattedDateTime === decodedDate) {
        return session;
      }
    }
  }
  return null;
}

export function findMostRecentSession(data) {
  let mostRecentSession = null;
  let mostRecentTimestamp = 0;

  for (const session of data) {
    for (const entry of session) {
      let entryTimestamp = entry.timestamp;

      if (entryTimestamp > mostRecentTimestamp) {
        mostRecentTimestamp = entryTimestamp;
        mostRecentSession = session;
      }
    }
  }

  return mostRecentSession;
}

export function findMatchingEntriesWithWithdrawal(array1, array2) {
  const datesArray1 = array1.map((entry) => entry.timestamp);
  const matchingEntries = array2.filter((entry) => datesArray1.includes(entry.timestamp));

  let foundWithdrawal = false;
  const resultEntries = [];

  for (const entry of matchingEntries) {
    if (!foundWithdrawal) {
      resultEntries.push(entry);
      if (entry.withdrawalOrTransferOut.value === true) {
        foundWithdrawal = true;
      }
    }
  }

  return resultEntries;
}

export function filterEntriesByDate(data, startDate, endDate) {
  const filteredEntries = data.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return entryDate >= start && entryDate <= end;
  });

  const endDateEntries = filteredEntries.filter((entry) => entry.date === endDate);
  if (endDateEntries.length > 1) {
    const hasTrueValue = endDateEntries.some((entry) => entry.withdrawalOrTransferOut.value);
    if (hasTrueValue) {
      return filteredEntries.filter((entry) => !(entry.date === endDate && !entry.withdrawalOrTransferOut.value));
    }
  }
  return filteredEntries;
}

export const calculateCostBasis = (data) => {
  return Number(data.exitedCostUsd);
};

export const calculateMarketGains = (data) => {
  return Number(data.exitedNetGainUsd) + Number(data.exitedCollectedIncomeUsd);
};

export const calculateMarketGainsPct = (data) => {
  return Number(data.exitedNetGainPct);
};

export const calculateExitValue = (data) => {
  return Number(data.exitedValueUsd);
};

export const calculateRoiVsHodl = (data) => {
  return Number(data.exitedValueUsd) - Number(data.exitedHodlValueUsd);
};

export const calculateRoiVsHodlPct = (data) => {
  return ((Number(data.exitedValueUsd) - Number(data.exitedHodlValueUsd)) / Number(data.exitedHodlValueUsd)) * 100;
};

export const calculatePendingIncomeUsd = (data) => {
  return Number(data.pendingIncomeUsd);
};

export const calculateCollectedIncomeUsd = (data) => {
  return Number(data.cumulativeCollectedIncomeUsd);
};

export const calculateExitedCollectedIncomeUsd = (data) => {
  return Number(data.exitedCollectedIncomeUsd);
};

function findLastExitedIndex(data) {
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i] && Object.keys(data[i]).length > 0) {
      return i;
    }
  }
  return -1; // Return -1 if no entry with populated 'withdrawalOrTransferOut.exited' is found
}

export const processIntervalData = (response, isExited) => {
  const rawData = isExited ? response.intervalData : response.currentIntervalData;

  const processedData = rawData.reduce((acc, entry) => {
    const existingEntryIndex = acc.findIndex((item) => item.positionIdentifier === entry.positionIdentifier);

    if (existingEntryIndex === -1) {
      acc.push(entry);
    } else if (new Date(acc[existingEntryIndex].date) < new Date(entry.date)) {
      acc[existingEntryIndex] = entry;
    }
    return acc;
  }, []);

  return processedData.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export const modifyAndFindMatchingPositionDataLB = (protocolPositionData, protocolPositionIntervalData) => {
  const aggregatedSnapshotData = [];

  protocolPositionData.forEach((entry) => {
    entry.snapshotData.forEach((snapshot) => {
      aggregatedSnapshotData.push(snapshot);
    });
  });

  const modifiedData = protocolPositionData.map((entry) => {
    return {
      ...entry,
      snapshotData: aggregatedSnapshotData,
      dayData: [...protocolPositionIntervalData?.snapshotIntervalData?.items],
      positionAgeSeconds: protocolPositionIntervalData?.snapshotIntervalData?.[0]?.positionAgeSeconds,
    };
  });

  return [modifiedData[0]];
};

export const modifyAndFindMatchingPositionData = (
  protocolPositionData,
  protocolPositionIntervalData,
  isExited,
  decodedDate,
  isProtocolLB,
) => {
  const modifiedData = (
    protocolPositionData?.positions?.items ||
    protocolPositionData?.data?.positions?.items ||
    []
  ).map((entry) => {
    return {
      ...entry,
      dayData: [...(protocolPositionIntervalData?.snapshotIntervalData?.items || [])],
      positionAgeSeconds: protocolPositionIntervalData?.snapshotIntervalData?.[0]?.positionAgeSeconds,
    };
  });
  if (isProtocolLB) {
    return modifiedData;
  }

  const matchingPositionData = modifiedData
    .flat()
    .map((item) => {
      if (isExited) {
        const sessions = splitSessions(item.dayData);
        const filteredSnapshots = findEntryByDate(sessions, decodedDate);
        const filteredDayData = filterEntriesByDate(
          item.dayData,
          filteredSnapshots[0].timestamp,
          filteredSnapshots[filteredSnapshots.length - 1].timestamp,
        );
        return {
          ...item,
          dayData: filteredDayData,
          userProtocolPositionSnapshots: filteredSnapshots,
        };
      } else if (item.snapshotData.some((item) => item.isFullyExitedSession === true)) {
        const sessions = splitSessions(item.snapshotData);
        const filteredSnapshots = findMostRecentSession(sessions);
        const firstSnapshotTimestampFromMostRecentSession = filteredSnapshots[0].timestamp;
        const filteredDayData = item.dayData.filter(
          (item) => item.timestamp >= firstSnapshotTimestampFromMostRecentSession,
        );

        return {
          ...item,
          dayData: filteredDayData,
          userProtocolPositionSnapshots: filteredSnapshots,
        };
      }

      return item;
    })
    .filter(Boolean);
  return matchingPositionData;
};

export const prettifyAndReduceIncomeData = (value) => {
  return displayPrettyNumber(
    value.reduce((accumulator, object) => {
      return parseFloat(accumulator) + parseFloat(object.val);
    }, 0),
  );
};

export const yieldColumns = [
  {
    id: 'yield',
    numeric: true,
    disablePadding: false,
    label: 'Yield',
  },
  {
    id: 'yieldUSD',
    numeric: true,
    disablePadding: false,
    label: 'Yield USD',
  },
  {
    id: 'apyUSD',
    numeric: true,
    disablePadding: false,
    label: 'APY USD',
  },
  {
    id: 'yieldInTokens',
    numeric: true,
    disablePadding: false,
    label: 'Yield in tokens',
  },
  {
    id: 'tokenAPY',
    numeric: true,
    disablePadding: false,
    label: 'Token APY',
  },
];

export const incomeColumns = [
  {
    id: 'collectedIncome',
    numeric: true,
    disablePadding: false,
    label: 'Collected income',
  },
  {
    id: 'pendingIncome',
    numeric: true,
    disablePadding: false,
    label: 'Pending income',
  },
  {
    id: 'totalIncome',
    numeric: true,
    disablePadding: false,
    label: 'Total income',
  },
  {
    id: 'incomeApy',
    numeric: true,
    disablePadding: false,
    label: 'Income APY',
  },
];

export const incomeColumnsExited = [
  {
    id: 'collectedIncome',
    numeric: true,
    disablePadding: false,
    label: 'Collected income',
  },
  {
    id: 'incomeApy',
    numeric: true,
    disablePadding: false,
    label: 'Income APY',
  },
];

export const incomeTokenColumns = [
  {
    id: 'tokenName',
    numeric: true,
    disablePadding: false,
    label: '',
  },
  {
    id: 'collected',
    numeric: true,
    disablePadding: false,
    label: 'Collected',
  },
  {
    id: 'pending',
    numeric: true,
    disablePadding: false,
    label: 'Pending',
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
  },
];

export const incomeTokenColumnsExited = [
  {
    id: 'tokenName',
    numeric: true,
    disablePadding: false,
    label: '',
  },
  {
    id: 'collected',
    numeric: true,
    disablePadding: false,
    label: 'Collected',
  },
];

export const hodlColumns = [
  {
    id: 'thisPositionValue',
    numeric: true,
    disablePadding: false,
    label: 'This position value',
  },
  {
    id: 'ifHeld',
    numeric: true,
    disablePadding: false,
    label: 'If held',
  },
  {
    id: 'roiVsHodl',
    numeric: true,
    disablePadding: false,
    label: 'ROI vs HOLD',
  },
  {
    id: 'if100%ETH',
    numeric: true,
    disablePadding: false,
    label: 'If 100% ETH',
  },
  {
    id: 'if100%BTC',
    numeric: true,
    disablePadding: false,
    label: 'If 100% BTC',
  },
];

export const renderColumnTokens = (columnData, entry, isMobile, isAlternativeLayout, protocolKey) => {
  const chainId = getChainId(protocolKey);
  return (
    <ul
      className={
        isMobile ? 'blog-meta' : isAlternativeLayout ? 'blog-meta-tokens alternative-layout' : 'blog-meta-tokens'
      }
    >
      {columnData.map((column) => (
        <li key={column.id + entry.tokenAddress}>
          <div className="d-flex align-items-center">
            {column.label.length > 0 ? (
              `${column.label}:`
            ) : (
              <TokenLogo chainId={chainId} address={entry.tokenAddress} sizeraw={12} size={'20px'} />
            )}{' '}
            {getColumnValue(column.id, entry)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export const renderColumnTokensExited = (
  columnData,
  entry,
  isMobile,
  isAlternativeLayout,
  protocolKey,
  shouldPurgeExitedTokenLogo,
) => {
  const chainId = getChainId(protocolKey);
  const maybeRenderTokenLogo = () =>
    shouldPurgeExitedTokenLogo ? (
      <></>
    ) : (
      <TokenLogo chainId={chainId} address={entry.tokenAddress} sizeraw={12} size={'20px'} />
    );
  return (
    <ul
      className={
        isMobile ? 'blog-meta' : isAlternativeLayout ? 'blog-meta-tokens alternative-layout' : 'blog-meta-tokens'
      }
    >
      {columnData.map((column) => (
        <li key={column.id + entry.tokenAddress}>
          <div className="d-flex align-items-center">
            {column.label.length > 0 ? `${column.label}:` : maybeRenderTokenLogo()}{' '}
            {getColumnValueExited(column.id, entry)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export const getAmountWithdrawnOrDepositedChange = (token) => {
  return token.amountDepositedChange === '0' && Number(token.amountWithdrawnChange) > 0
    ? `+${displayPrettyNumber(token.amountWithdrawnChange)}`
    : `${displayPrettyNumber(token.amountDepositedChange)}`
    ? `-${displayPrettyNumber(token.amountDepositedChange)}`
    : `+${displayPrettyNumber(token.amountWithdrawnChange)}`;
};

export const getAmountWithdrawnOrDepositedChangeNumberOnly = (token) => {
  return token.amountDepositedChange === '0' && Number(token.amountWithdrawnChange) > 0
    ? Number(token.amountWithdrawnChange)
    : Number(token.amountDepositedChange)
    ? Number(token.amountDepositedChange)
    : Number(token.amountWithdrawnChange);
};

const formatTokenTag = (str) => {
  if (!str) return str;

  const handleCamelCase = (camelStr) => {
    let result = '';
    for (let i = 0; i < camelStr.length; i++) {
      if (camelStr[i] === camelStr[i].toUpperCase() && i !== 0) {
        result += ' ' + camelStr[i].toLowerCase();
      } else {
        result += camelStr[i];
      }
    }
    return result;
  };

  let formattedStr = str.charAt(0).toUpperCase() + str.slice(1);

  return handleCamelCase(formattedStr);
};

export const assembleStatusMessage = (
  data,
  onClickFunction,
  activeTab,
  successfulQueriesCount,
  positionsLength,
  isExited,
) => {
  const { headBlock, lastSyncedBlock } = data;

  const classificationOfTransactionsStatus = lastSyncedBlock === headBlock ? 'done' : 'not done';
  const fetchedPositionsStatus =
    headBlock !== lastSyncedBlock ? 'not done' : successfulQueriesCount === positionsLength ? 'done' : 'not done';
  return (
    <>
      <div style={{ alignItems: 'center', justifyContent: 'space-between' }} className="d-flex">
        <div>
          <div>Indexing data, use update button in a moment if all the data isn't showing done.</div>
          <div style={{ flexWrap: 'wrap' }} className="d-flex">
            <div className="d-flex">
              Classification of transactions: ({classificationOfTransactionsStatus}){' '}
              <CustomTooltip
                content={`At the time of this query we have indexed up to block ${lastSyncedBlock} of ${headBlock} blocks`}
              >
                {' '}
                <InfoCircle fill={'var(--color-primary)'} className="info-icon" />
              </CustomTooltip>{' '}
            </div>
          </div>
          <div style={{ flexWrap: 'wrap' }} className="d-flex">
            <div className="d-flex">
              {isExited
                ? `Returning all exited sessions from ${successfulQueriesCount} of ${
                    lastSyncedBlock !== headBlock ? '?' : positionsLength
                  } positions: (${fetchedPositionsStatus})`
                : `Fetching any current positions from ${successfulQueriesCount} of ${
                    lastSyncedBlock !== headBlock ? '?' : positionsLength
                  } total positions: (${fetchedPositionsStatus})`}
            </div>
          </div>
        </div>
        <a onClick={onClickFunction} className={`edu-btn btn-update`}>
          Update
        </a>
      </div>
    </>
  );
};

export const assembleStatusMessageDetailsPage = (data, onClickFunction) => {
  const {
    totalPositionsStatus: { headBlock: totalPositionsHeadBlock, lastSyncedBlock: totalPositionsLastSyncedBlock },
    snapshotIndexingStatus: { headBlock: snapshotIndexingHeadBlock, lastSyncedBlock: snapshotIndexingLastSyncedBlock },
    dependentIndexingStatus: {
      headBlock: dependentIndexingHeadBlock,
      lastSyncedBlock: dependentIndexingLastSyncedBlock,
    },
  } = data;

  const classificationOfTransactionsStatus =
    totalPositionsLastSyncedBlock === totalPositionsHeadBlock ? 'done' : 'not done';

  const indexingEachDayWithATx =
    snapshotIndexingLastSyncedBlock === snapshotIndexingHeadBlock
      ? 'done'
      : `currently ${snapshotIndexingHeadBlock - snapshotIndexingLastSyncedBlock} blocks behind`;

  const indexingDependentData =
    dependentIndexingHeadBlock === dependentIndexingLastSyncedBlock
      ? 'done'
      : `currently ${dependentIndexingHeadBlock - dependentIndexingLastSyncedBlock} blocks behind`;

  return (
    <>
      <div style={{ alignItems: 'center', justifyContent: 'space-between' }} className="d-flex">
        <div>
          <div>Indexing data, use update button in a moment to return more data.</div>
          <div style={{ flexWrap: 'wrap' }} className="d-flex">
            <div className="d-flex">Classification of transactions: ({classificationOfTransactionsStatus}) </div>
          </div>
          <div style={{ flexWrap: 'wrap' }} className="d-flex">
            <div className="d-flex">Indexing each day with a transaction: ({indexingEachDayWithATx})</div>
          </div>
          {dependentIndexingHeadBlock !== 0 && dependentIndexingLastSyncedBlock !== 0 && (
            <div style={{ flexWrap: 'wrap' }} className="d-flex">
              <div className="d-flex">Calculating daily totals for the Income report: ({indexingDependentData})</div>
            </div>
          )}
        </div>
        <a onClick={onClickFunction} className={`edu-btn btn-update`}>
          Update
        </a>
      </div>
    </>
  );
};

export const flattenData = (data) => {
  const flattenObject = (obj, parentKey = '', res = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = parentKey ? parentKey + '_' + key : key;
        if (typeof obj[key] == 'object' && obj[key] !== null) {
          if (obj[key] instanceof Array) {
            obj[key].forEach((item, index) => {
              flattenObject(item, `${propName}_${index}`, res);
            });
          } else {
            flattenObject(obj[key], propName, res);
          }
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  };

  return data.map((item) => flattenObject(item));
};

export const isVaultsProtocol = (protocolKey) => {
  return protocolKey.includes('lido') || protocolKey.includes('rocketpool_income');
};

export const checkDataValidity = (data) => {
  if (data.onDemandIntervalData !== 0) {
    return false;
  }
  for (const key in data) {
    if (key !== 'onDemandIntervalData' && data[key] <= 0) {
      return false;
    }
  }

  return true;
};

export const formatAddress = (address) => {
  const maxLength = 13;
  if (address.length >= maxLength) {
    return address.slice(0, 6) + '...' + address.slice(-6);
  }
  return address;
};

export const getChainId = (key) => {
  const chainMappings = {
    eth: 1,
    polygon: 137,
    avalanche: 43114,
    arbitrum: 42161,
    bsc: 56,
    optimism: 10,
    evmos: 9001,
    solana: -8768,
    aurora: 1313161554,
    base: 8453,
    ronin: 2020,
    gnosis: 100,
  };

  for (const [chainKey, chainId] of Object.entries(chainMappings)) {
    if (key.includes(chainKey)) {
      return chainId;
    }
  }
  return 1;
};

export const headersDescriptionsForActivePositions = [
  { Header: 'Protocol', Description: 'The protocol of the position', Source: 'Protocol' },
  { Header: 'Position ID', Description: 'The position ID', Source: 'positionIdentifier' },
  { Header: 'Chain ID', Description: 'The chain ID', Source: 'ChainID' },
  { Header: 'Chain ID - Name', Description: 'The chain IDs name', Source: 'chainIdName' },
  { Header: 'User Address', Description: 'The address of the wallet in question', Source: 'UserAddress' },
  { Header: 'Block Number', Description: 'The block number for this summary', Source: 'blockNumber' },
  { Header: 'Timestamp', Description: 'Data is as of this timestamp', Source: 'timestamp' },
  {
    Header: 'Timestamp - Human readable',
    Description: 'Data is as of this timestamp',
    Source: 'timestampHumanReadable',
  },
  { Header: 'Position Age', Description: 'Length of time position has been open', Source: 'positionAge' },
  {
    Header: 'Position Shares At Block',
    Description: 'The number of position shares at the time of this report',
    Source: 'positionSharesAtBlock',
  },
  {
    Header: 'Average Open Interest USD',
    Description: 'The average deposited amount in USD for the course of the position (averaged over time)',
    Source: 'avgOpenInterestUsd',
  },
  {
    Header: 'Collected Income USD',
    Description:
      "We calculate the incomeUsd for each group and multiply it by 1 minus exitRatio and we return the sum of each group's result",
    Source: 'cumulativeCollectedIncomeUsd',
  },
  {
    Header: 'Pending Income USD',
    Description: 'The pending income for this position to be collected',
    Source: 'pendingIncomeUsd',
  },
  {
    Header: 'Position USD Value at Block',
    Description: 'The position’s value in USD at the block',
    Source: 'positionUsdValueAtBlock',
  },
  { Header: 'Net Market Gain', Description: 'The gain in USD terms of the position', Source: 'netMarketGainUsd' },
  {
    Header: 'Is Liability Position',
    Description: 'Denotes whether or not this position is a debt position',
    Source: 'isLiabilityPosition',
  },
];

export const headersDescriptionsForActiveTokens = [
  { Header: 'Protocol', Description: 'The protocol of the position', Source: 'Protocol' },
  { Header: 'Position ID', Description: 'The position ID', Source: 'positionIdentifier' },
  { Header: 'Chain ID', Description: 'The chain ID', Source: 'ChainID' },
  { Header: 'Chain ID - Name', Description: 'The chain IDs name', Source: 'chainIdName' },
  { Header: 'User Address', Description: 'The address of the wallet in question', Source: 'UserAddress' },
  { Header: 'Block Number', Description: 'The block number for this summary', Source: 'blockNumber' },
  { Header: 'Timestamp', Description: 'Data is as of this timestamp', Source: 'timestamp' },
  {
    Header: 'Timestamp - Human readable',
    Description: 'Data is as of this timestamp',
    Source: 'timestampHumanReadable',
  },
  { Header: 'Position Age', Description: 'Length of time position has been open', Source: 'positionAge' },
  { Header: 'Token Name', Description: 'The name of the token', Source: 'tokenName' },
  { Header: 'Token Address', Description: 'The address of the token', Source: 'tokenAddress' },
  {
    Header: 'Pending Income Amount',
    Description: 'Token amount of any pending income',
    Source: 'pendingIncomeAmount',
  },
  {
    Header: 'Collected Income Amount',
    Description: 'Token amount of any collected income allocated to this session (partial exits reduce this amount)',
    Source: 'collectedIncomeAmount',
  },
  {
    Header: 'Total Income Amount',
    Description:
      'Pending + Collected = Total. Total token amount of any income allocated to this session (partial exits reduce this amount)',
    Source: 'totalIncomeAmount',
  },
  {
    Header: 'Track Underlying Token Amount at Block',
    Description: 'Token amount at the end of the block',
    Source: 'tokenAmountAtBlock',
  },
  {
    Header: 'Net Basis Token Amount',
    Description: 'Tracks the net amount of token added to the position',
    Source: 'ifHeldAmountToken',
  },
  { Header: 'Token Price', Description: 'The token price at the end of the block', Source: 'priceUsd' },
  {
    Header: 'Token Value in USD',
    Description: 'The token value in USD at the end of the block',
    Source: 'tokenValueUsd',
  },
  { Header: 'Token Tag', Description: 'A tag with more information about the token', Source: 'tag' },
  {
    Header: 'Token Type',
    Description: 'The token type. Currently one of NON_RECEIPT, RECEIPT, INCOME, FEE',
    Source: 'tokenType',
  },
];

export const headersDescriptionsForExitedPositions = [
  { Header: 'Protocol', Description: 'The protocol of the position', Source: 'Protocol' },
  { Header: 'Position ID', Description: 'The position ID', Source: 'positionIdentifier' },
  { Header: 'Chain ID', Description: 'The chain ID', Source: 'ChainID' },
  { Header: 'Chain ID - Name', Description: 'The chain IDs name', Source: 'chainIdName' },
  { Header: 'User Address', Description: 'The address of the wallet in question', Source: 'UserAddress' },
  { Header: 'Underlying Tokens', Description: 'The underlying tokens in the position', Source: '' },
  { Header: 'Block Number', Description: 'The block number of the position exit', Source: 'blockNumber' },
  { Header: 'Timestamp', Description: 'Data is as of this timestamp', Source: 'timestamp' },
  {
    Header: 'Timestamp - Human readable',
    Description: 'Data is as of this timestamp',
    Source: 'timestampHumanReadable',
  },
  { Header: 'Position Age', Description: 'Length of time position was open for', Source: 'positionAge' },
  {
    Header: 'Is Liability Position',
    Description: 'Denotes whether or not this position is a debt position',
    Source: 'isLiabilityPosition',
  },
  {
    Header: 'Exited Cost Basis USD',
    Description: 'The average cost basis of the withdrawn portion of the position',
    Source: 'exitedCostUsd',
  },
  {
    Header: 'Exited Hodl Value Usd',
    Description: 'Hypothetical value at exit of original tokens in their original amounts',
    Source: 'exitedHodlValueUsd',
  },
  {
    Header: 'Exited Roi vs Hodl Usd',
    Description: 'Exited Value USD minus Exited Hodl Value',
    Source: 'exitedRoiVsHodlUsd',
  },
  {
    Header: 'Exited Value USD',
    Description:
      'The value in USD of the withdrawn portion of the position at the time of exit any separately claimed income not included',
    Source: 'exitedValueUsd',
  },
  {
    Header: 'Exited Net Gain USD',
    Description: 'The net gain or loss for the exited portion of a position',
    Source: 'exitedNetGainUsd',
  },
  {
    Header: 'Exited Net Gain Percentage',
    Description: 'The net market gain or loss of the exited portion of the transaction',
    Source: 'exitedNetGainPct',
  },
  {
    Header: 'Exit Ratio',
    Description: 'Value between 0 and 1 indicating the proportion of the position exited in this transaction',
    Source: 'exitRatio',
  },
  {
    Header: 'Exited Collected Income USD',
    Description: 'The collected income in USD allocated proportionally to the exited session',
    Source: 'exitedCollectedIncomeUsd',
  },
  {
    Header: 'Exited Position Shares At Block',
    Description: 'The position shares exited in the transaction',
    Source: 'exitedPositionSharesAtBlock',
  },
  {
    Header: 'Exited User Principal Shares',
    Description: 'The basis number of shares exited in the transaction',
    Source: 'exitedUserPrincipalShares',
  },
  {
    Header: 'Exited Position Share Change',
    Description: 'The difference between the base shares and the exited position shares',
    Source: 'exitedPositionShareChange',
  },
  {
    Header: 'Exited Position Share Change USD',
    Description: 'The USD value of the Exited Position Share Change',
    Source: 'exitedPositionShareChangeUsd',
  },
];

export const headersDescriptionsForTransactions = [
  { Header: 'Protocol', Description: 'The protocol of the position', Source: 'Protocol' },
  { Header: 'Position Identifier', Description: 'The identifier of the position', Source: 'positionIdentifier' },
  { Header: 'Chain ID', Description: 'The chain ID', Source: 'ChainID' },
  { Header: 'Chain ID - Name', Description: 'The chain IDs name', Source: 'chainIdName' },
  { Header: 'Transaction Hash', Description: 'The transaction hash of the transaction', Source: 'txHash' },
  { Header: 'Address', Description: 'The address of the wallet in question', Source: 'UserAddress' },
  { Header: 'Block Number', Description: 'The block number for this summary', Source: 'blockNumber' },
  { Header: 'Timestamp', Description: 'Data is as of this timestamp', Source: 'timestamp' },
  {
    Header: 'Timestamp - Human readable',
    Description: 'Data is as of this timestamp',
    Source: 'timestampHumanReadable',
  },
  {
    Header: 'Operation Type',
    Description:
      'This is the operation we have identified for the transaction. Note that one transaction can have multiple operations for a position',
    Source: 'operationType',
  },
  {
    Header: 'Shares Amount At Block',
    Description: 'The shares that we have identified of the position at the end of the block',
    Source: 'positionSharesAtBlock',
  },
  {
    Header: 'Asset Value USD',
    Description: 'The position’s value in USD at the time',
    Source: 'positionUsdValueAtBlock',
  },
  { Header: 'Gas Fee USD', Description: 'The gas fee paid for the transaction', Source: 'txFeeUsd' },
  { Header: 'Gas Amount', Description: 'The gas amount that was used for the transaction', Source: 'gasTokenAmount' },
];

export const headerDescriptionsForOperationTokens = [
  {
    Header: 'Protocol',
    Description: 'The protocol of the position',
    Source: 'Protocol',
  },
  {
    Header: 'Position ID',
    Description: 'The position ID',
    Source: 'positionIdentifier',
  },
  {
    Header: 'Transaction Hash',
    Description: 'The transaction hash of the transaction',
    Source: 'txHash',
  },
  {
    Header: 'Address',
    Description: 'The address of the wallet in question',
    Source: 'UserAddress',
  },
  {
    Header: 'Block Number',
    Description: 'The block number for this summary',
    Source: 'blockNumber',
  },
  { Header: 'Timestamp', Description: 'Data is as of this timestamp', Source: 'timestamp' },
  {
    Header: 'Timestamp - Human readable',
    Description: 'Data is as of this timestamp',
    Source: 'timestampHumanReadable',
  },
  {
    Header: 'Token Name',
    Description: 'The token name',
    Source: 'tokenName',
  },
  {
    Header: 'Token Raw Amount',
    Description: 'The raw token amount before it is converted to a decimal number',
    Source: 'tokenRawAmount',
  },
  {
    Header: 'Decimals',
    Description: 'The number of decimals to apply to the raw token amount to convert it',
    Source: 'decimals',
  },
  {
    Header: 'Total Amount Deposited',
    Description: 'The total token amount deposited in this session of the position',
    Source: 'totalAmountDeposited',
  },
  {
    Header: 'Total Amount withdrawn',
    Description: 'The total token amount withdrawn in this session of the position',
    Source: 'totalAmountWithdrawn',
  },
  {
    Header: 'Amount Deposited Change',
    Description: 'The amount deposited in this tx',
    Source: 'amountDepositedChange',
  },
  {
    Header: 'Amount Withdrawn Change',
    Description: 'The amount withdrawn in this tx',
    Source: 'amountWithdrawnChange',
  },
  {
    Header: 'Amount Income Change',
    Description: 'The amount of income claimed in this tx',
    Source: 'amountIncomeChange',
  },
  {
    Header: 'Token Income USD',
    Description: 'The USD value of the income claimed in this tx',
    Source: 'tokenIncomeUsd',
  },
  {
    Header: 'Token Price USD',
    Description: 'The USD token price at this block',
    Source: 'tokenPriceUsd',
  },
  {
    Header: 'Token Price Source',
    Description: 'The source the price came from',
    Source: 'tokenPriceSource',
  },
  {
    Header: 'Token Amount At Block',
    Description: 'The amount of the token in the position after any given operation in the tx is complete',
    Source: 'tokenAmountAtBlock',
  },
  {
    Header: 'Net Basis Token Amount',
    Description:
      'The amount of this token if the original amount was held and not put in the position - deposits increase this amount and withdraws decrease it',
    Source: 'ifHeldAmountToken',
  },
  {
    Header: 'Net Token Amount',
    Description: 'The net amount of token just based on the deposits and withdraws',
    Source: 'netTokenAmount',
  },
  {
    Header: 'Token Value Usd',
    Description: 'The value of the token change in this tx',
    Source: 'tokenValueUsd',
  },
  {
    Header: 'Virtual token',
    Description:
      'A true or false field that is true if the token amount shown is derived and not explicitly known from the tx logs',
    Source: 'isVirtualToken',
  },
  {
    Header: 'Token Tag',
    Description: 'A tag with more information about the token',
    Source: 'tag',
  },
  {
    Header: 'Token Direction',
    Description: 'For example input and output - from the perspective of the protocol and not the user address',
    Source: 'tokenDirection',
  },
  {
    Header: 'Token Type',
    Description: 'The token type. Currently one of NON_RECEIPT, RECEIPT, INCOME, FEE',
    Source: 'tokenType',
  },
];

export function transformDataForCsv(data, staticData, headersDescriptions, includeHeaders) {
  const csvRows = [];
  if (includeHeaders) {
    const headersRow = headersDescriptions.map((hd) => hd.Header);
    const descriptionsRow = headersDescriptions.map((hd) => hd.Description);
    csvRows.push(headersRow, descriptionsRow);
  }
  const dataRow = headersDescriptions.map((hd) => {
    let value;
    if (hd.Header === 'Operation Type') {
      value = data.operations ? data.operations.map((operation) => operation.operationType).join(', ') : '';
    } else if (hd.Header === 'Underlying Tokens') {
      value = data.tokens
        ? data.tokens
            .filter((token) => token.tokenType === 'non_receipt')
            .map((token) => token.tokenName.toUpperCase())
            .join('-')
        : '';
    } else if (staticData && staticData.hasOwnProperty(hd.Source)) {
      value = staticData[hd.Source];
    } else if (data.hasOwnProperty(hd.Source)) {
      value = data[hd.Source];
    } else {
      value = '';
    }
    value = value === null ? 'null' : value;

    return value.toString();
  });

  csvRows.push(dataRow);
  return csvRows;
}

export const shouldReturnNegatedValues = (item) => {
  return {
    ...item,
    netMarketGainUsd: Number(item.netMarketGainUsd) * -1,
    positionUsdValueAtBlock: Number(item.positionUsdValueAtBlock) * -1,
    userPrincipalCostUsd: Number(item.userPrincipalCostUsd) * -1,
    netMarketGainPct: Number(item.netMarketGainPct) * -1,
    positionShareAPY: Number(item.positionShareAPY) * -1,
    positionShareChangeUsd: Number(item.positionShareChangeUsd) * -1,
  };
};

export const mapEntryToTableRow = (entry, columns, isExited) => {
  const row = {};
  columns.forEach((column) => {
    row[column.id] = isExited ? getColumnValueExited(column.id, entry) : getColumnValue(column.id, entry);
  });
  return [row];
};

export const calculatePercentageDifference = (value1, value2) => {
  return ((value1 - value2) / value2) * 100;
};

export const fontSize16AndFontWeight600 = { fontSize: 16, fontWeight: 600 };

export const prettifyProtocolKey = (key) => {
  // Split the input string into segments
  const segments = key.split('_');

  // Determine how many segments to include based on the count of underscores
  const relevantSegments = segments.length > 2 ? segments.slice(0, 2) : segments.slice(0, 1);

  // Process each segment to capitalize the first letter
  const processedSegments = relevantSegments.map(
    (segment) => segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase(),
  );

  // Join the relevant segments with a space for two segments, otherwise just return the single segment
  return processedSegments.join(segments.length > 2 ? ' ' : '');
};

export const findProtocolByKey = (obj, keyToFind) => {
  if (Array.isArray(obj.keys) && obj.keys.includes(keyToFind)) {
    return obj;
  }

  if (obj.hasOwnProperty(keyToFind)) {
    return obj[keyToFind];
  }

  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) continue;

      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        const result = findProtocolByKey(value, keyToFind);
        if (result !== undefined) {
          return result;
        }
      }
    }
  }

  return undefined;
};

export const getChainLogo = (protocolKey) => {
  if (protocolKey.includes('eth')) {
    return '/assets/images/crypto-logos/eth_logo.svg';
  } else if (protocolKey.includes('polygon')) {
    return '/assets/images/crypto-logos/polygon_logo.svg';
  } else if (protocolKey.includes('arbitrum')) {
    return '/assets/images/crypto-logos/arbitrum_logo.svg';
  } else if (protocolKey.includes('optimism')) {
    return '/assets/images/crypto-logos/optimism_logo.svg';
  } else if (protocolKey.includes('bsc')) {
    return '/assets/images/crypto-logos/bsc_logo.svg';
  } else if (protocolKey.includes('base')) {
    return '/assets/images/crypto-logos/base_logo.svg';
  } else if (protocolKey.includes('avalanche')) {
    return '/assets/images/crypto-logos/avalanche_logo.svg';
  } else if (protocolKey.includes('solana')) {
    return '/assets/images/crypto-logos/solana_logo.svg';
  } else if (protocolKey.includes('ronin')) {
    return '/assets/images/crypto-logos/ronin_logo.svg';
  } else if (protocolKey.includes('evmos')) {
    return '/assets/images/crypto-logos/evmos_logo.svg';
  } else if (protocolKey.includes('cosmos')) {
    return '/assets/images/crypto-logos/cosmos_logo.svg';
  } else if (protocolKey.includes('gnosis')) {
    return '/assets/images/crypto-logos/gnosis.svg';
  } else if (protocolKey.includes('zksync')) {
    return '/assets/images/crypto-logos/zksync.webp';
  } else if (protocolKey.includes('scroll')) {
    return '/assets/images/crypto-logos/scroll.svg';
  }
};

export const standardChartTitleStyle = {
  fontSize: 16,
  fontWeight: 600,
  color: 'white',
  textAlign: 'left',
};
export const appendPlusOrMinus = (value) => {
  if (Number(value) > 0) {
    return '+';
  } else {
    return '-';
  }
};

export const reconfigureEntries = (entries) => {
  // Helper to extract version from key
  const getVersionFromKey = (key) => {
    const match = key.match(/_v(\d+)/);
    return match ? match[1] : null;
  };

  // Helper to normalize keys
  const normalizeKey = (key, protocolId) => {
    // Check if it's a farming or income variant from the protocolId
    const isFarming = key.includes('farming');
    const isIncome = key.includes('income');
    const isveAero = key.includes('veaero');
    // Extract version
    const version = getVersionFromKey(key);
    // Get base name (including farming/income if present)
    const baseName = isveAero
      ? 'aerodrome_veaero'
      : isFarming
      ? 'sushiswap_farming'
      : isIncome
      ? 'rocketpool_income'
      : key.split('_')[0];

    return version ? `${baseName}_v${version}` : baseName;
  };

  // Group entries by their normalized key
  const entryGroups = entries.reduce((groups, entry) => {
    const normalizedKey = normalizeKey(entry.key, entry.protocolId);
    if (!groups[normalizedKey]) {
      groups[normalizedKey] = [];
    }
    groups[normalizedKey].push(entry);
    return groups;
  }, {});

  const statusPriority = {
    beta: 4,
    dev: 3,
    alpha: 2,
    'pre-alpha': 1,
  };

  return Object.entries(entryGroups).flatMap(([normalizedKey, group]) => {
    // If there's only one entry in the group, keep its original format
    if (group.length === 1) {
      const entry = group[0];

      return [
        {
          ...entry,
          name: entry.name.includes('veAERO') ? 'Aerodrome veAERO Staking' : entry.name,
          chain: entry.chain,
          key: entry.key,
        },
      ];
    }

    // For multiple entries, use the simplified format
    const bestEntry = group.reduce((best, current) => {
      const bestPriority = statusPriority[best.status] || 0;
      const currentPriority = statusPriority[current.status] || 0;
      return currentPriority > bestPriority ? current : best;
    }, group[0]);

    return [
      {
        ...bestEntry,
        key: normalizedKey, // Use the normalized key
        name: bestEntry.name,
        chain: '', // Clear chain only for grouped entries
      },
    ];
  });
};

const cleanName = (nameWithoutVersion) => {
  const chain = getChain(nameWithoutVersion);

  if (chain) {
    // Remove the chain name and any extra spaces, handle potential "v1", "v2" etc
    const withoutChain = nameWithoutVersion.replace(new RegExp(chain, 'i'), '').replace(/\s+/g, ' ').trim();

    // Handle version numbers if they exist
    const versionMatch = withoutChain.match(/\s*v\d+\s*/i);
    if (versionMatch) {
      const version = versionMatch[0].trim();
      const baseName = withoutChain.replace(versionMatch[0], '').trim();
      return `${baseName} ${version}`;
    }

    return withoutChain;
  }

  return nameWithoutVersion;
};

export const handleApiRequest = async (account, query, variables) => {
  const queryAddress = variables?.userIdentifier;
  const authToken = getLocalStorage(AUTH_TOKEN_KEY);
  const shouldUse = shouldUseAuthToken(queryAddress, account, authToken);
  if (shouldUse) {
    try {
      const response = await axios.post(
        `${process.env.NEXT_ARCHIVE_API_MAIN}/graphql`,
        {
          query: print(query),
          variables,
        },
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage(AUTH_TOKEN_KEY)}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      console.error(e);
      toast.error(`Something went wrong: ${e.message}`);
      return null;
    }
  } else {
    try {
      const response = await axios.post(`${process.env.NEXT_ARCHIVE_API_PROXY}/graphql`, {
        query: print(query),
        variables,
      });
      return response.data;
    } catch (e) {
      if (e.message === 'Network Error') {
        toast.warning(
          <div>
            Too many requests, please buy our{' '}
            <a
              style={{ color: 'black', textDecoration: 'underline' }}
              href="https://zora.co/collect/base:0xe74e84955eca6448c22a9051c2573bacb698523e/1"
              target="_blank"
              rel="noopener noreferrer"
            >
              NFT
            </a>{' '}
            to get unlimited access.
          </div>,
        );
        return null;
      } else {
        toast.error(`Something went wrong: ${e.message}`);
        return null;
      }
    }
  }
};

export const findAndSetProtocolObject = (protocols, setProtocolObject, setIsFetchingProtocolObject, protocolKey) => {
  const protocolObject = protocols.find((protocol) => protocol.key === protocolKey);
  if (protocolObject) {
    setProtocolObject(protocolObject);
    setIsFetchingProtocolObject(false);
  } else {
    setIsFetchingProtocolObject(false);
  }
};

export const fetchProtocolsAndSet = async (setIsFetchingProtocolObject, setProtocolObject, protocolKey) => {
  try {
    const response = await axios.get(`${process.env.NEXT_ARCHIVE_API_PROXY}/blueprint/getProtocolKeys`);
    const newProtocols = reconfigureEntries(response.data);
    localStorage.setItem('protocols', JSON.stringify(newProtocols));
    findAndSetProtocolObject(newProtocols, setProtocolObject, setIsFetchingProtocolObject, protocolKey);
  } catch (error) {
    console.error('Failed to fetch protocols', error);
    setIsFetchingProtocolObject(false);
  }
};

export const getButtonBasedOnTitle = (title) => {
  switch (title) {
    case 'Contact Us':
      return (
        <button style={{ background: 'transparent', border: '0px', padding: '0px' }} id={'mava-webchat-launcher'}>
          <a className="mb-20">{title}</a>
        </button>
      );
    case 'Subscribe':
      return (
        <button
          style={{ background: 'transparent', border: '0px', padding: '0px' }}
          id="block_cta"
          data-tally-open="wa4OoB"
          data-tally-layout="modal"
          data-tally-width="550"
          data-tally-hide-title="1"
        >
          <a className="mb-20">{title}</a>
        </button>
      );
    case 'Request API Access':
      return (
        <button
          onClick={() => {
            logEvent('clicked_request_API_access_footer');
          }}
          style={{ background: 'transparent', border: '0px', padding: '0px', whiteSpace: 'nowrap' }}
          id="main_cta"
          data-tally-open="3joeEJ"
          data-tally-layout="modal"
          data-tally-width="550"
          data-tally-hide-title="1"
          data-tally-auto-close="0"
        >
          <a className="mb-20">{title}</a>
        </button>
      );
  }
};

const renderArrow = () => (
  <img style={{ paddingLeft: 8, paddingRight: 8 }} src="/assets/images/arrow-right.svg" alt="arrow right" />
);

export const renderOgImage = () => {
  return 'https://i.imgur.com/bb1BSkQ.png';
};

export const shouldUseAuthToken = (queryAddress, account, authToken) => {
  const isNotDemoWallet = queryAddress?.toLowerCase() !== DEMO_WALLET;
  const hasAuthToken = !!authToken;
  return isNotDemoWallet && hasAuthToken;
};

export const isBlueprintNonEVM = (blueprintKey) => {
  return blueprintKey.includes('solana') || blueprintKey.includes('cosmos');
};

export function formatEntry(key, entry, isExited) {
  switch (key) {
    case 'positionAge':
      const duration = moment.duration(isExited ? entry.exitedSessionLength : entry.positionAgeSeconds, 'seconds');
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      return `${days} days, ${hours} hours, ${minutes} minutes`;
    default:
      return '';
  }
}

export const needsResync = (indexingStatus) => {
  if (!indexingStatus) {
    return true;
  }

  const { headBlock, lastSyncedBlock } = indexingStatus;
  return headBlock !== lastSyncedBlock || lastSyncedBlock === -1;
};

export const createResult = (key, data, error, needsSync, requestId) => ({
  key,
  data,
  error,
  loading: false,
  needsSync,
  requestId,
});

export const getKeysToQuery = (resultsWithErrors, outOfSyncDataset, keys, protocolKey) => {
  const isKeyOutOfSync = (key) => outOfSyncDataset?.some((item) => item.key === key);
  const isKeyWithError = (key) => resultsWithErrors?.some((item) => item.key === key);

  if (outOfSyncDataset?.length === 0) {
    const initialKeys = keys?.length > 0 ? keys : [protocolKey];
    return initialKeys.filter((key) => !isKeyWithError(key));
  }

  if (keys?.length > 0) {
    return keys.filter((key) => isKeyOutOfSync(key)).filter((key) => !isKeyWithError(key));
  }

  if (isKeyOutOfSync(protocolKey) && !isKeyWithError(protocolKey)) {
    return [protocolKey];
  }

  return [];
};

export const buildKeyToIdentifierMap = (resultsToResolve) => {
  const keyToIdentifiersMap = new Map();

  resultsToResolve.forEach((result) => {
    const { key, data } = result;
    const identifiers = data?.totalPositions?.activePositions || [];

    identifiers.forEach((identifier) => {
      if (!keyToIdentifiersMap.has(key)) {
        keyToIdentifiersMap.set(key, new Set());
      }
      keyToIdentifiersMap.get(key).add(identifier);
    });
  });
  return keyToIdentifiersMap;
};

export function getChainBlockTime(protocolKey) {
  const chain = extractChain(protocolKey);

  const blockTimes = {
    eth: 12,
    bsc: 3,
    polygon: 2,
    arbitrum: 0.25,
    avalanche: 2,
    aurora: 1,
    evmos: 2,
    cosmoshub: 6,
    solana: 0.4,
    ronin: 3,
    optimism: 2,
    base: 2,
  };

  return blockTimes[chain] || null;
}

function extractChain(protocolKey) {
  const chainIdentifiers = [
    'eth',
    'bsc',
    'polygon',
    'arbitrum',
    'avalanche',
    'aurora',
    'evmos',
    'cosmoshub',
    'solana',
    'ronin',
    'optimism',
    'base',
  ];
  for (const identifier of chainIdentifiers) {
    if (protocolKey.includes(identifier)) {
      return identifier;
    }
  }

  return null;
}

export const calculateTimeDifference = (protocolKey, indexingStatus) => {
  const absoluteDifference = Math.abs(indexingStatus?.headBlock - indexingStatus?.lastSyncedBlock);
  const blockTimeInSeconds = getChainBlockTime(protocolKey);

  if (!blockTimeInSeconds) {
    console.error(`Unknown block time for protocol: ${protocolKey}`);
    return null;
  }

  const totalSeconds = absoluteDifference * blockTimeInSeconds;

  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);

  return { days, hours, minutes };
};

export const getProtocolManifestValue = (protocolKey, isDetailsPage) => {
  if (!isDetailsPage) {
    return protocolManifestMap[protocolKey] || protocolKey;
  }

  // Extract base protocol (e.g., "aave_v2" from "aave_v2_polygon")
  const baseProtocol = protocolKey.split('_').slice(0, 2).join('_');
  return protocolManifestMap[baseProtocol] || protocolKey;
};

// Predefined list of protocols that we know have multiple chain implementations
const MULTICHAIN_PROTOCOLS = [
  'aave_v3',
  'aave_v2',
  'compound_v3',
  'clipper_v1',
  'ichi_v1',
  'bril_v1',
  'morpho_v1',
  'spark_v1',
  'fluid_v1',
  'uniswap_v3',
  'balancer_v2',
  // Add more protocols as needed
];

export const simplifiedReconfigureEntries = (entries) => {
  // Helper to extract version from key
  const getVersionFromKey = (key) => {
    const match = key.match(/_v(\d+)/);
    return match ? match[1] : null;
  };

  // Helper to get protocol with version
  const getProtocolWithVersion = (protocolId, version) => {
    if (version) {
      return `${protocolId}_v${version}`;
    }
    // Check if the protocolId already contains a version (like aave_v2_ethereum)
    const versionMatch = protocolId.match(/_v(\d+)/);
    return versionMatch ? protocolId.split('_').slice(0, 2).join('_') : protocolId;
  };

  // Group entries by protocol and version
  const entryGroups = entries.reduce((groups, entry) => {
    const baseProtocol = entry.protocolId.split('_')[0];
    const version = getVersionFromKey(entry.key);
    const protocolWithVersion = getProtocolWithVersion(baseProtocol, version);

    // Handle grouping logic
    let groupKey;

    if (MULTICHAIN_PROTOCOLS.includes(protocolWithVersion)) {
      // For known multi-chain protocols with specific versions
      groupKey = protocolWithVersion;
    } else {
      // For single-chain protocols or non-multichain protocols, use the original key
      groupKey = entry.key;
    }

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(entry);
    return groups;
  }, {});

  const processedEntries = {};

  // Process each group into the final format
  for (const [protocolKey, group] of Object.entries(entryGroups)) {
    const baseProtocol = protocolKey.split('_')[0];
    const version = getVersionFromKey(protocolKey);

    // Generate prettified name
    let prettifiedName;
    if (protocolKey.includes('sushiswap_farming')) {
      prettifiedName = 'Sushiswap Farming';
    } else if (protocolKey.includes('aerodrome_veaero')) {
      prettifiedName = 'Aerodrome veAERO Staking';
    } else {
      // Capitalize first letter of protocol name
      const protocolName = baseProtocol.charAt(0).toUpperCase() + baseProtocol.slice(1);

      // Determine version string
      let versionStr = '';
      if (version) {
        versionStr = ` V${version}`;
      } else if (group[0].name.toLowerCase().includes('v2')) {
        versionStr = ' V2';
      } else if (group[0].name.toLowerCase().includes('v3')) {
        versionStr = ' V3';
      }

      prettifiedName = `${protocolName}${versionStr}`;
    }

    // Get the entry with highest status priority
    const statusPriority = {
      beta: 4,
      dev: 3,
      alpha: 2,
      'pre-alpha': 1,
    };

    const bestEntry = group.reduce((best, current) => {
      const bestPriority = statusPriority[best.status] || 0;
      const currentPriority = statusPriority[current.status] || 0;
      return currentPriority > bestPriority ? current : best;
    }, group[0]);

    processedEntries[protocolKey] = {
      prettifiedName,
      status: bestEntry.status,
    };
  }

  return processedEntries;
};

export const getImageForProtocol = (protocol) => {
  const protocolKey = protocol.split('_')[0].toLowerCase();
  const images = [
    'aave-aave-logo.svg',
    'avalanche-avax-logo.svg',
    'balancer-bal-logo.svg',
    'clipper-logo.jpg',
    'compound-comp-logo.svg',
    'cosmoshub-atom-logo.svg',
    'ethereum-eth-logo.svg',
    'evmos.svg',
    'gamma-strategies-gamma-logo.svg',
    'retro-ichi-logo.webp',
    'lido-dao-ldo-logo.svg',
    'maker-mkr-logo.svg',
    'orca-orca-logo.svg',
    'pancakeswap-cake-logo.svg',
    'polygon-matic-logo.svg',
    'rocketpool-eth-reth-logo.svg',
    'katana-ronin-ron-logo.svg',
    'sushiswap-sushi-logo.svg',
    'uniswap-uni-logo.svg',
    'verse-logo.png',
    'morpho-logo.png',
    'aerodrome-logo.svg',
    'spark-logo.svg',
    'bril-logo.png',
    'quickswap-logo.png',
    'spacefi-logo.png',
    'trisolaris-logo.ico',
    'across-logo.png',
    'fluid-logo.svg',
    'velodrome-logo.svg',
    'moonwell-logo.png',
  ];

  const matchingImage = images.find((img) => img.toLowerCase().includes(protocolKey));
  return matchingImage ? `/assets/supported-user-protocols/${matchingImage}` : null;
};
